import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  FlexColCenterHorizontal,
  TextP,
  FlexRowCenterVertical,
  DsiSvgIcon as SvgIcon,
  DsiProductCartTableList as ProductCartTableList,
  DsiCartDeliveryPart as CartDeliveryPart,
  DsiCartContactInfoPart as CartContactInfoPart,
  DsiCartSummary as CartSummary,
  DsiEmptyCart as EmptyCart,
} from 'eshop-defaults';
import { __, __r } from 'react-i18n';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link } from 'react-router';
import { setCartError, updateCart } from '../../containers/Cart/cartSlice';
import API, { ThenArg } from '../../services/API';
import MetaTags from '../_helpers/MetaTags/MetaTags';
import { prop } from '../../utilities';
import { resolveArticleContentDetails } from '../../utilities/article';
import { ConfirmModal } from './ConfirmModal';
import { resolveDomainByHostname } from '../../configureTrans';
import { ChooseModal } from './ChooseModal';
import { theme } from '../../theme/theme';

interface Props {
  dispatch: any;
  showProblems: any;
  isFetching: boolean;
  freeDeliveryLimit: number;
  actualPrice: number;
  data: any;
  delAndPayInfoById: any;
  isFetchingDelAndPayInfo: boolean;
  user: any;
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
  deliveryAddressesIsFetching: boolean;
  handleAcceptTermsChange: (e: any) => void;
  handleHeurekaTermsChange: (e: any) => void;
  handleCompleteOrder: (publicId: string) => void;
  handleGoBack: () => void;
  addToCart: any;
  removeFromCart: any;
  couponCode: string;
  onCouponCodeChanged: (couponCode: string) => void;
  lang: string;
  addCoupon: any;
  removeCoupon: any;
  freeDelivery: ThenArg<typeof API.getFreeDeliveryInfo>;
  couponError: string | null;
  hostname: string;
  onGiftCardModeSelected: (selectType) => void;
}

function reducer(state, { field, value }) {
  return { ...state, [field]: value };
}

export const getInitialStateFromCartData = (data: any, isB2B) => {
  return {
    email: prop(data, 'billing_address.person.email', ''),
    phone: prop(data, 'billing_address.person.phone', ''),
    use_delivery_address: true,
    billing_company:
      isB2B && prop(data, 'company.ico')
        ? true
        : prop(data, 'billing_company', false),
    note: prop(data, 'note', ''),
    cart_label: prop(data, 'cart_label', ''),
    vat_payer: prop(data, 'vat_payer', false) || false,
    heureka_refuse: prop(data, 'heureka_refuse', false) || false,
    terms_accept: prop(data, 'terms_accept', false) || false,
    register: prop(data, 'register', false) || false,
  };
};

export const getInitialStateFromCartDataForContest = (data: any, isB2B) => {
  return {
    email: prop(data, 'billing_address.person.email', ''),
    phone: prop(data, 'billing_address.person.phone', ''),
    use_delivery_address: true,
    note: prop(data, 'note', ''),
    cart_label: prop(data, 'cart_label', ''),
    vat_payer: prop(data, 'vat_payer', false) || false,
    heureka_refuse: prop(data, 'heureka_refuse', false) || false,
    terms_accept: prop(data, 'terms_accept', false) || false,
  };
};

function Cart(props: Props) {
  const {
    freeDeliveryLimit,
    actualPrice,
    data,
    isFetchingDelAndPayInfo,
    delAndPayInfoById,
    dispatch,
    deliveryAddresses,
    user,
    isFetching,
    showProblems,
    deliveryAddressesIsFetching,
    handleAcceptTermsChange,
    handleHeurekaTermsChange,
    handleCompleteOrder,
    handleGoBack,
    addToCart,
    removeFromCart,
    lang,
    couponCode,
    onCouponCodeChanged,
    addCoupon,
    removeCoupon,
    freeDelivery,
    couponError,
    hostname,
    onGiftCardModeSelected,
  } = props;

  const greenLoaderWidthPercentage =
    freeDeliveryLimit !== 0 ? actualPrice / (freeDeliveryLimit / 100) : 0;

  const formRef = React.useRef<HTMLFormElement>(null);
  let updateTimer: any = React.useRef(null);

  const initialState = getInitialStateFromCartData(data, false);
  const [state, dispatchState] = React.useReducer(reducer, initialState);
  const [isMovingToNextStep, setIsMovingToNextStep] = React.useState(false);
  const [isCreatingOrder, setIsCreatingOrder] = React.useState(false);
  const [cartWarningText, setCartWarningText] = React.useState<string>('');
  const [knownIcoWarningShowed, setKnownIcoWarningShowed] = React.useState<
    boolean
  >(false);
  const [
    showKnownIcoWarningModal,
    setShowKnownIcoWarningModal,
  ] = React.useState<boolean>(false);
  const [knownIcoWarningText, setKnownIcoWarningText] = React.useState<string>(
    '',
  );

  const [onCountRemoveModal, setOnCountRemoveModal] = React.useState<{
    productId;
    goodId;
    productName;
  } | null>(null);

  const [hideModalGiftcardMode, setHideModalGiftcardMode] = React.useState<
    boolean
  >(false);

  React.useEffect(() => {
    API.loadOtherTexts('WARNING_IN_CART', {
      langId: lang,
      domainId: +resolveDomainByHostname(hostname),
      fallbackDomainId: 0,
    }).then(loadOtherTextsResult => {
      if (loadOtherTextsResult && loadOtherTextsResult.content) {
        if (!loadOtherTextsResult.content.draft) {
          const content = resolveArticleContentDetails(
            loadOtherTextsResult.content,
          );
          if (content && content.body) {
            setCartWarningText(content.body);
          }
        }
      }
    });

    API.loadOtherTexts('WARNING_KNOWN_ICO', {
      langId: lang,
      domainId: +resolveDomainByHostname(hostname),
      fallbackDomainId: 0,
    }).then(loadOtherTextsResult => {
      if (
        loadOtherTextsResult &&
        loadOtherTextsResult.content &&
        !loadOtherTextsResult.content.draft
      ) {
        const content = resolveArticleContentDetails(
          loadOtherTextsResult.content,
        );
        if (content && content.body) {
          setKnownIcoWarningText(content.body);
        }
      }
    });
  }, []);

  const onCountRemoveFromCart = (productId, goodId, productName) => {
    setOnCountRemoveModal({ productId, goodId, productName });
  };

  const handleUpdateCart = data => {
    dispatch(updateCart({ ...data, ...state }, true, couponCode));
  };

  const handleItemUpdateCart = updatedItem => {
    const itemToUpdateIndex = data.items.findIndex(
      item => item.good.good_id === updatedItem.good.good_id,
    );
    const cartCopy = { ...data };
    const cartCopyItems = [...cartCopy.items];
    cartCopyItems[itemToUpdateIndex] = updatedItem;

    cartCopy['items'] = cartCopyItems;
    dispatch(updateCart({ ...cartCopy, ...state }, true, couponCode));
  };

  const handleDispatchState = ({ field, value }) => {
    dispatchState({ field, value });

    if (field === 'note' || field === 'cart_label') {
      if (updateTimer.current) {
        clearTimeout(updateTimer.current);
      }

      updateTimer.current = setTimeout(() => {
        dispatch(updateCart({ ...data, [field]: value }, true, couponCode));
      }, 1000);
    }
  };

  const handleFinishOrder = async () => {
    if (data?.has_known_ico && knownIcoWarningText) {
      setKnownIcoWarningShowed(true);
      setShowKnownIcoWarningModal(true);
    } else {
      setIsCreatingOrder(true);
      setIsMovingToNextStep(true);
      try {
        const form = prop(formRef, 'current.elements');
        if (form) {
          const newData = {
            ...props.data,
            billing_address: {
              ...props.data.billing_address,
              city: prop(form['bcity'], 'value', ''),
              country: prop(form['bcountry'], 'value', ''),
              street: prop(form['bstreet'], 'value', ''),
              street_number: prop(form['bstreet_number'], 'value', ''),
              zip: prop(form['bzip'], 'value', ''),
              person: {
                name:
                  prop(form['bname'], 'value', '') ||
                  prop(form['name'], 'value', ''),
                surname:
                  prop(form['bsurname'], 'value', '') ||
                  prop(form['surname'], 'value', ''),
                phone:
                  prop(form['phone_prefix'], 'value', '') +
                  prop(form['phone'], 'value', ''),
                email: prop(form['email'], 'value', ''),
                phonePrefix: prop(form['phone_prefix'], 'value', ''),
              },
            },
            delivery_address: {
              ...props.data.delivery_address,
              city: prop(form['city'], 'value', ''),
              country: prop(form['country'], 'value', ''),
              street: prop(form['street'], 'value', ''),
              street_number: prop(form['street_number'], 'value', ''),
              zip: prop(form['zip'], 'value', ''),
              person: {
                name: prop(form['name'], 'value', ''),
                surname: prop(form['surname'], 'value', ''),
                phone:
                  prop(form['phone_prefix'], 'value', '') +
                  prop(form['phone'], 'value', ''),
                email: prop(form['email'], 'value', ''),
                phonePrefix: prop(form['phone_prefix'], 'value', ''),
              },
            },
            company: {
              ...props.data.company,
              name: prop(form['companyName'], 'value'),
              ico: prop(form['ico'], 'value'),
              dic: prop(form['dic'], 'value'),
              ic_dph: prop(form['ic_dph'], 'value'),
            },
            password: prop(form['password'], 'value'),
            password_again: prop(form['password_again'], 'value'),
          };

          const res = await API.createOrder(
            data.id,
            {},
            { ...newData, ...state, step: 3 },
          );
          handleCompleteOrder(res.orderPublicId);
        } else {
          const res = await API.createOrder(
            data.id,
            {},
            { ...props.data, ...state, step: 3 },
          );
          handleCompleteOrder(res.orderPublicId);
        }
      } catch (err) {
        if (
          err &&
          err.details.payload &&
          err.details.payload &&
          err.details.payload.cartProblems
        ) {
          dispatch(setCartError(err.details.payload.cartProblems));
        } else if (prop(err, 'details.name')) {
          dispatch(setCartError([{ readable: prop(err, 'details.name') }]));
        }
      } finally {
        setIsCreatingOrder(false);
        setIsMovingToNextStep(false);
      }
    }
  };

  return (
    <Wrapper className="container container--wide">
      <MetaTags tags={{ title: __('Košík') }} />

      {showKnownIcoWarningModal ? (
        <ConfirmModal
          titleText={__('Upozornenie')}
          body={knownIcoWarningText}
          buttonText={__('Zatvoriť')}
          handleClose={() => {
            setShowKnownIcoWarningModal(false);
          }}
          handleButtonClick={() => {
            setShowKnownIcoWarningModal(false);
          }}
        />
      ) : (
        <></>
      )}

      {onCountRemoveModal ? (
        <ChooseModal
          titleText={__('Odobrať tovar z košíka?')}
          handleClose={() => {
            setOnCountRemoveModal(null);
          }}
          buttons={[
            {
              handleClick: () => {
                setOnCountRemoveModal(null);
              },
              text: __('Neodobrať'),
            },
            {
              handleClick: () => {
                setOnCountRemoveModal(null);
                removeFromCart(
                  onCountRemoveModal.productId,
                  onCountRemoveModal.goodId,
                );
              },
              text: __('Odobrať tovar'),
              backgroundColor: theme.colors.primary,
              textColor: theme.colors.white,
            },
          ]}
        />
      ) : (
        <></>
      )}

      {data && data.items.length > 0 ? (
        <>
          <LeftSide>
            <StyledTitle>{__('Nákupný košík')}</StyledTitle>
            {cartWarningText ? (
              <WarningBlock
                dangerouslySetInnerHTML={{ __html: cartWarningText }}
              />
            ) : (
              <></>
            )}
            {/*
              {greenLoaderWidthPercentage !== 0 && (
                <FreeDeliveryLoader>
                  <FreeDeliveryText>
                    {__('Nakúpte ešte za ')}
                    <StrongText>{`${freeDeliveryLimit -
                      actualPrice} €`}</StrongText>
                    {' a'}
                    {__(' získajte ')}
                    <StrongText>{__('dopravu zadarmo')}</StrongText>.
                  </FreeDeliveryText>
                  <Loader>
                    <GreenLoader width={greenLoaderWidthPercentage} />
                  </Loader>
                </FreeDeliveryLoader>
              )}
              */}

            <ProductCartTableList
              products={data ? data.items : null}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
              onCountRemoveFromCart={onCountRemoveFromCart}
              updateCart={handleItemUpdateCart}
            />

            <CartDeliveryPart
              data={data}
              delAndPayInfoById={delAndPayInfoById}
              isFetchingDelAndPayInfo={isFetchingDelAndPayInfo}
              updateCart={handleUpdateCart}
              isB2B={false}
              user={null}
              lang={lang}
            />
            <CartContactInfoPart
              data={data}
              isFetching={isFetching}
              updateCart={handleUpdateCart}
              deliveryAddresses={deliveryAddresses}
              deliveryAddressesIsFetching={deliveryAddressesIsFetching}
              showProblems={showProblems}
              isB2B={false}
              lang={lang}
              state={state}
              dispatchState={handleDispatchState}
              formRef={formRef}
              isB2CUser={user && !user.b2b}
            />
          </LeftSide>
          <Summary>
            <StyledTitle>{__('Sumár')}</StyledTitle>
            <CartSummary
              handleAcceptTermsChange={handleAcceptTermsChange}
              handleHeurekaTermsChange={handleHeurekaTermsChange}
              data={data}
              isFetching={isFetching}
              isCreatingOrder={isCreatingOrder}
              user={user}
              handleCompleteOrder={handleFinishOrder}
              handleGoBack={handleGoBack}
              couponCode={couponCode}
              onCouponCodeChanged={onCouponCodeChanged}
              addCoupon={addCoupon}
              removeCoupon={removeCoupon}
              state={state}
              dispatchState={handleDispatchState}
              couponError={couponError}
              lang={lang}
            />
          </Summary>
        </>
      ) : (
        <EmptyCart />
      )}

      {data?.should_prompt_giftcard_mode && !hideModalGiftcardMode ? (
        <ChooseModal
          titleText={__(
            'Zľavový kupón nie je možné kombinovať s akciou v košíku. Čo si želáte spraviť?',
          )}
          desktopButtonsLayout="column"
          handleClose={() => {
            setHideModalGiftcardMode(true);
          }}
          buttons={[
            {
              handleClick: () => {
                onGiftCardModeSelected(1);
              },
              text: __(
                'Neaplikovať akcie v košíku a aplikovať zľavový kupón na všetky produkty.',
              ),
            },
            {
              handleClick: () => {
                onGiftCardModeSelected(2);
              },
              text: __('Aplikovať zľavový kupón len na produkty bez akcie.'),
            },
          ]}
        />
      ) : (
        <></>
      )}
    </Wrapper>
  );
}

const StyledTitle = styled.h1`
  font-size: ${rem(30)};
  font-weight: 400;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.m810`
    font-size: ${rem(24)};
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${({ theme }) => theme.mediab.l1300`
    justify-content: space-between;
  `}
  ${({ theme }) => theme.mediab.l1150`
    padding-top: ${rem(32)};
    flex-direction: column;
  `}
`;

const LeftSide = styled(FlexCol)`
  width: 100%;
  max-width: ${rem(944)};
  margin-right: ${rem(128)};
  ${({ theme }) => theme.mediab.l1300`
    max-width: 100%;
    margin-right: ${rem(56)};
  `}
`;

const Summary = styled(FlexCol)`
  max-width: ${rem(345)};
  height: 100%;
  position: sticky;
  top: ${rem(16)};
  flex: 25%;
  margin-left: auto;

  ${({ theme }) => theme.mediab.l1300`
    max-width: ${rem(250)};
  `};

  ${({ theme }) => theme.mediab.l1150`
    max-width: ${rem(200)};
  `};

  ${({ theme }) => theme.mediab.l925`
    margin 0 auto;
  `};

  ${({ theme }) => theme.mediab.m580`
     max-width:initial;
  `};
`;

const FreeDeliveryLoader = styled(FlexColCenterHorizontal)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: ${rem(4)};
  padding: ${rem(24)} 0;
  margin-bottom: ${rem(32)};

  ${({ theme }) => theme.mediab.l925`
    padding: ${rem(16)};
    text-align: center;
  `}
`;

const FreeDeliveryText = styled(TextP)`
  ${({ theme }) => theme.mediab.m810`
    font-size: ${rem(14)};
  `}
`;

const Loader = styled.div`
  margin-top: ${rem(16)};
  height: ${rem(4)};
  width: ${rem(464)};
  background-color: rgba(30, 57, 141, 0.08);
  border-radius: ${rem(2)};
  margin-bottom: 0;

  ${({ theme }) => theme.mediab.l925`
    max-width: 100%;
  `}
`;

const GreenLoader = styled(Loader)<{ width: number }>`
  width: ${({ width }) => width}%;
  background-color: ${({ theme }) => theme.cart.cartSummary.primary};
  position: relative;
  top: ${rem(-16)};
`;

const StrongText = styled(TextP)`
  font-weight: 500;
  display: inline;
`;

const LoginPanel = styled(FlexColCenterHorizontal)`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border: 2px solid rgba(30, 57, 141, 0.12);
  border-radius: ${rem(4)};
  padding: ${rem(16)} 0;
`;

const MediumTextP = styled(TextP)`
  font-weight: 500;
  margin-right: ${rem(4)};
  ${({ theme }) => theme.mediab.m810`
    font-size: ${rem(14)};
  `}
`;

const BlueMediumTextP = styled(MediumTextP)`
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const WarningBlock = styled.div`
  background: ${({ theme }) => theme.colors.dangerColor};
  margin-bottom: ${rem(20)};
  padding-left: ${rem(15)};
  padding-right: ${rem(15)};
`;

export { Cart };
