import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  CorporateFooterRowWrapper as FooterRowWrapper,
  TextP,
  DsiSvgIcon,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { Link } from 'react-router';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { css } from 'styled-components';
import { __ } from 'react-i18n';
import { Icon } from 'eshop-defaults';

const FooterWrapper = styled.footer`
  z-index: 1;
  background: ${({ theme }) => theme.footer.background};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  justify-content: center;
  flex-flow: column;
  /* margin-top: ${rem(40)}; */

  @media print {
    display: none;
  }
`;

const NeedHelp = styled(FlexRowCenterVertical)`
  margin-right: ${rem(16)};
  flex-wrap: wrap;

  ${({ theme }) => theme.mediab.l925`
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
  `};

  a:last-child {
    margin-bottom: 0;
  }
`;

const textLikeCss = css`
  font-size: ${rem(16)};
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.footer.footerLink};
  line-height: ${rem(19)};
  font-weight: 400;

  ${({ theme }) => theme.mediab.l925`
      font-size: ${rem(14)};
      line-height: ${rem(18)};
  `};
`;

const SmallTextP = styled(TextP)`
  ${textLikeCss};

  ${({ theme }) => theme.mediab.l925`
      margin-bottom: ${rem(8)};
  `};
`;

const SmallTextA = styled.a`
  ${textLikeCss};

  text-decoration: none;
  text-align: center;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const SmallTextLink = styled(Link)<{ isActive?: boolean }>`
  font-size: ${rem(18)};
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.footer.footerPrimaryLink};
  line-height: ${rem(22)};
  font-weight: 500;
  margin: 0;
  text-decoration: none;

  padding-top: ${rem(40)};
  padding-bottom: ${rem(24)};
  margin-right: ${rem(32)};

  border-bottom: ${props => (props.isActive ? `${rem(1)} solid #FFFFFF` : ``)};

  &:hover,
  &:focus {
    text-decoration: none;
  }

  ${({ theme }) => theme.mediab.l925`
    width: initial;
    text-align: left;
    margin-bottom: ${rem(16)};
    line-height: ${rem(26)};
    padding: 0;
    border-bottom: none !important;
    margin-right: 0;
  `}
`;

const Delimeter = styled.span`
  margin: 0 ${rem(24)};
  height: ${rem(20)};
  border-right: ${({ theme }) => theme.borders.footer};
  display: inline-block;

  ${({ theme }) => theme.mediab.l925`
    display: none;
  `}
`;

const ThirdRow = styled(FlexRowCenterVertical)`
  ${({ theme }) => theme.mediab.l925`
      flex-direction: column;
      order: 2;
  `};
`;

const SocialUrlsWrapper = styled(FlexRowCenterVertical)`
  margin: ${rem(8)} 0;

  ${({ theme }) => theme.mediab.l925`
      order: 1;
      margin-top: 0;
      margin-bottom: ${rem(32)};
  `};
`;

const SocialLink = styled.a`
  display: inline-flex;
  width: ${rem(56)};
  height: ${rem(56)};
  justify-content: center;
  align-items: center;
  border-radius: ${rem(4)};
  border: ${rem(2)} solid #ffffff29;
  margin-left: ${rem(8)};

  ${({ theme }) => theme.mediab.l925`
      &:first-child {
        margin-left: 0;
      }
  `};
`;

const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => theme.mediab.l925`
    flex-direction: column;
  `};
`;

const PaymentsWrapper = styled(SocialUrlsWrapper)``;

const PaymentIcon = styled(Icon)`
  height: ${rem(30)};
  margin-right: ${rem(10)};
`;

interface Props {
  items: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  phone: string;
  email: string;
  socialUrls: {
    [key: string]: string;
  };
  currentLocation: string;
}

interface State {
  breakInnerWidth: number;
}

class Footer extends React.Component<Props, State> {
  public render() {
    return (
      <FooterWrapper>
        {this.renderFirstRow()}
        {this.renderSecondRow()}
      </FooterWrapper>
    );
  }

  private renderFirstRow = () => {
    const { items, currentLocation } = this.props;
    if (!items || items.length === 0) {
      return null;
    }
    return (
      <FooterRowWrapper desktopPadding={`${rem(0)} ${rem(12)}`}>
        <NeedHelp>
          {items &&
            items.map((item: any) => (
              <SmallTextLink
                key={item.id}
                to={'/' + item.url}
                isActive={Boolean(currentLocation.match(item.url))}
              >
                {item.name}
              </SmallTextLink>
            ))}
        </NeedHelp>
      </FooterRowWrapper>
    );
  };

  private renderSecondRow = () => {
    const { socialUrls } = this.props;

    return (
      <FooterRowWrapper desktopPadding={`${rem(32)} ${rem(12)}`}>
        <ThirdRow>
          <SmallTextP>{__('Copyright ©2021 DSI Slovakia')}</SmallTextP>
          <Delimeter />
          <SmallTextA href="https://www.bart.sk/" target="_blank">
            {__('Vytvoril bart.sk')}
          </SmallTextA>
        </ThirdRow>
        <RightWrapper>
          <>{this.renderPaymentIcons()}</>
          {socialUrls && Object.keys(socialUrls).length > 0
            ? this.renderSocialIcons(socialUrls)
            : null}
        </RightWrapper>
      </FooterRowWrapper>
    );
  };

  private renderSocialIcons = (socialUrls: {
    [key: string]: string;
  }): JSX.Element => {
    const social: Array<JSX.Element> = [];

    for (let [objkey, value] of Object.entries(socialUrls)) {
      const elm = (
        <SocialLink
          href={value}
          target="_blank"
          title={__('Link na sociálnu sieť')}
          key={objkey}
        >
          {objkey === 'fbUrl' && (
            <DsiSvgIcon
              icon={IconType.facebook}
              viewBox="12 12 24 24"
              alt="Facebook link"
              cursor="pointer"
            />
          )}
          {objkey === 'instagramUrl' && (
            <DsiSvgIcon
              icon={IconType.instagram}
              viewBox="12 12 24 24"
              alt="Instagram link"
              cursor="pointer"
            />
          )}
          {objkey === 'youtubeUrl' && (
            <DsiSvgIcon
              icon={IconType.youtube}
              viewBox="12 12 24 24"
              alt="Youtube link"
              cursor="pointer"
            />
          )}
        </SocialLink>
      );
      social.push(elm);
    }
    return <SocialUrlsWrapper>{social}</SocialUrlsWrapper>;
  };

  private renderPaymentIcons = () => {
    return (
      <PaymentsWrapper>
        <PaymentIcon src="/images/payment_home/cg-hor.svg" />
        <PaymentIcon src="/images/payment_home/Visa_Brandmark_Blue_RGB_2021.svg" />
        <PaymentIcon src="/images/payment_home/mc_symbol.svg" />
        <PaymentIcon src="/images/payment_home/google-pay.svg" />
        <PaymentIcon src="/images/payment_home/apple-pay.svg" />
      </PaymentsWrapper>
    );
  };
}

export default Footer;
