import { hostnameSelector } from './../App/selectors';
import API from '../../services/API';
import {
  REQUEST_HOME_ARTICLES,
  RECEIVE_HOME_ARTICLES_SUCCESS,
  RECEIVE_HOME_ARTICLES_ERROR,
  REQUEST_HOME_RECOMMENDED,
  RECEIVE_HOME_RECOMMENDED_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_ERROR,
  REQUEST_HOME_FAST_NEWS,
  RECEIVE_HOME_FAST_NEWS_SUCCESS,
  RECEIVE_HOME_FAST_NEWS_ERROR,
  REQUEST_HOME_TOP_ARTICLE,
  RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  RECEIVE_HOME_TOP_ARTICLE_ERROR,
  REQUEST_HOME_EBOOKS,
  RECEIVE_HOME_EBOOKS_SUCCESS,
  RECEIVE_HOME_EBOOKS_ERROR,
  SET_HOME_RECOMMENDED_FILTER,
  RECEIVE_HOME_BANNER_SUCCESS,
  SET_HOME_ARTICLES_FILTERS,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  RECEIVE_HOME_TOP_BRANDS_SUCCESS,
  REQUEST_HOME_PRODUCT_LIST2,
  RECEIVE_HOME_PRODUCT_LIST2_SUCCESS,
  RECEIVE_HOME_PRODUCT_LIST2_ERROR,
  REQUEST_HOME_PRODUCT_LIST1,
  RECEIVE_HOME_PRODUCT_LIST1_SUCCESS,
  RECEIVE_HOME_PRODUCT_LIST1_ERROR,
  REQUEST_HOME_CATEGORIES,
  RECEIVE_HOME_CATEGORIES_ERROR,
  RECEIVE_HOME_CATEGORIES_SUCCESS,
  REQUEST_REVIEWS,
  RECEIVE_REVIEWS_SUCCESS,
  RECEIVE_REVIEWS_ERROR,
  RECEIVE_BLOG_ARTICLES_ERROR,
  REQUEST_BLOG_ARTICLES,
  RECEIVE_BLOG_ARTICLES_SUCCESS,
  REQUEST_HOME_FAVORITE_PRODUCTS,
  RECEIVE_HOME_FAVORITE_PRODUCTS_SUCCESS,
  RECEIVE_HOME_FAVORITE_PRODUCTS_ERROR,
  REQUEST_HOME_RECOMMENDED_PRODUCTS,
  RECEIVE_HOME_RECOMMENDED_PRODUCTS_SUCCESS,
  RECEIVE_HOME_RECOMMENDED_PRODUCTS_ERROR,
  REQUEST_HOME_SHOPPING_CONSULTANTS,
  RECEIVE_HOME_SHOPPING_CONSULTANTS_SUCCESS,
  RECEIVE_HOME_SHOPPING_CONSULTANTS_ERROR,
  REQUEST_HOME_TOP_TECHNOLOGIES,
  RECEIVE_HOME_TOP_TECHNOLOGIES_SUCCESS,
  REQUEST_NOT_FOUND_ARTICLES,
  RECEIVE_NOT_FOUND_ARTICLES_SUCCESS,
  RECEIVE_NOT_FOUND_ARTICLES_ERROR,
} from './constants';
import { prop } from '../../utilities';
import { langSelector } from '../App/selectors';
import { productList1Selector, productList2Selector } from './selectors';
import {
  DEFAULT_CATEGORY_ID,
  resolveDomainByHostname,
  resolveTagByHostname,
} from '../../configureTrans';
import { cartIdSelector } from '../Cart/cartSlice';

const requestHomeArticles = () => ({
  type: REQUEST_HOME_ARTICLES,
});

const receiveHomeArticlesSuccess = articles => ({
  type: RECEIVE_HOME_ARTICLES_SUCCESS,
  payload: {
    articles,
  },
});

const receiveHomeArticlesError = error => ({
  type: RECEIVE_HOME_ARTICLES_ERROR,
  payload: {
    error,
  },
});

const requestHomeProductList1 = () => ({
  type: REQUEST_HOME_PRODUCT_LIST1,
});

const receiveHomeProductList1Success = (products, categoryId: string) => ({
  type: RECEIVE_HOME_PRODUCT_LIST1_SUCCESS,
  payload: {
    products,
    categoryId,
  },
});

const receiveHomeProductList1Error = error => ({
  type: RECEIVE_HOME_PRODUCT_LIST1_ERROR,
  payload: {
    error,
  },
});

export const loadHomeArticles = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeArticles());
      const lang = langSelector(getState());
      const articles = await API.loadArticles(
        {
          // sitemapUniqueId: 'IMPORT',
          sfForm: 'Article',
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeArticlesSuccess(articles));
    } catch (e) {
      dispatch(receiveHomeArticlesError(e));
    }
  };
};

export const loadHomeProductList1 = (categoryId: string) => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const news = productList1Selector(state);

      if (!news || !news.productsData || !news.productsData[categoryId]) {
        dispatch(requestHomeProductList1());
        const products = await API.loadProductList('ACTION_FIRM');
        dispatch(receiveHomeProductList1Success(products, categoryId));
      }
    } catch (e) {
      dispatch(receiveHomeProductList1Error(e));
    }
  };
};

const requestHomeTopTechnologies = () => ({
  type: REQUEST_HOME_TOP_TECHNOLOGIES,
});

const receiveHomeTopTechnologiesSuccess = (articles: Array<any>) => ({
  type: RECEIVE_HOME_TOP_TECHNOLOGIES_SUCCESS,
  payload: {
    articles,
  },
});

const receiveHomeTopTechnologiesError = error => ({
  type: RECEIVE_HOME_TOP_ARTICLE_ERROR,
  payload: {
    error,
  },
});

export const loadHomeTopTechnologies = () => {
  return async (dispatch, getState, API) => {
    const lang = langSelector(getState());
    const currentState = getState();
    const hostname = hostnameSelector(currentState);

    try {
      dispatch(requestHomeTopTechnologies());
      const { articles } = await API.loadArticles(
        {
          sitemapUniqueId: `${resolveTagByHostname(hostname)}_TOP_TECH`,
          onlyActual: '1',
          isPublished: '1',
          hasProductsFromCategoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          withoutJsonContent: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeTopTechnologiesSuccess(articles));
    } catch (e) {
      dispatch(receiveHomeTopTechnologiesError(e));
    }
  };
};

const requestHomeShoppingConsultants = () => ({
  type: REQUEST_HOME_SHOPPING_CONSULTANTS,
});

const receiveHomeShoppingConsultantsSuccess = (articles: Array<any>) => ({
  type: RECEIVE_HOME_SHOPPING_CONSULTANTS_SUCCESS,
  payload: {
    articles,
  },
});

const receiveHomeShoppingConsultantsError = error => ({
  type: RECEIVE_HOME_SHOPPING_CONSULTANTS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeShoppingConsultants = () => {
  return async (dispatch, getState, API) => {
    const lang = langSelector(getState());
    const currentState = getState();
    const hostname = hostnameSelector(currentState);

    try {
      dispatch(requestHomeShoppingConsultants());
      const { articles } = await API.loadArticles(
        {
          sitemapUniqueId: `${resolveTagByHostname(hostname)}_SHOP_CONS`,
          onlyActual: '1',
          isPublished: '1',
          hasProductsFromCategoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          withoutJsonContent: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeShoppingConsultantsSuccess(articles));
    } catch (e) {
      dispatch(receiveHomeShoppingConsultantsError(e));
    }
  };
};

const requestHomeRecommendedProducts = () => ({
  type: REQUEST_HOME_RECOMMENDED_PRODUCTS,
});

const receiveHomeRecommendedProductsSuccess = (products: Array<any>) => ({
  type: RECEIVE_HOME_RECOMMENDED_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

const receiveHomeRecommendedProductsError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeRecommendedProducts = () => {
  return async (dispatch, getState, API) => {
    const hostname = hostnameSelector(getState());
    const lang = langSelector(getState());
    const cartId = cartIdSelector(getState());

    try {
      dispatch(requestHomeRecommendedProducts());
      const { products } = await API.loadElasticCategoryProducts(
        DEFAULT_CATEGORY_ID[hostname][lang],
        {
          limit: 10,
          domainId: resolveDomainByHostname(hostname),
          productListId: 'SHOP_CONSULTANTS',
          categoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          langId: lang,
          onlySubcategories: 1,
          cartId,
          projectType: 'home_slider',
        },
      );
      dispatch(receiveHomeRecommendedProductsSuccess(products.products));
    } catch (e) {
      dispatch(receiveHomeRecommendedProductsError(e));
    }
  };
};

const requestHomeFavoriteProducts = () => ({
  type: REQUEST_HOME_FAVORITE_PRODUCTS,
});

const receiveHomeFavoriteProductsSuccess = (products: Array<any>) => ({
  type: RECEIVE_HOME_FAVORITE_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

const receiveHomeFavoriteProductsError = error => ({
  type: RECEIVE_HOME_FAVORITE_PRODUCTS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeFavoritesProducts = () => {
  return async (dispatch, getState, API) => {
    const lang = langSelector(getState());
    const hostname = hostnameSelector(getState());
    const cartId = cartIdSelector(getState());

    try {
      dispatch(requestHomeFavoriteProducts());
      const { products } = await API.loadElasticCategoryProducts(
        DEFAULT_CATEGORY_ID[hostname][lang],
        {
          limit: 10,
          onlyAvailable: 1,
          sort: 'sales_agg',
          sortDir: 'desc',
          categoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          langId: lang,
          domainId: resolveDomainByHostname(hostname),
          onlySubcategories: 1,
          onlyWithoutParent: 1,
          cartId,
          projectType: 'home_slider',
        },
      );
      dispatch(receiveHomeFavoriteProductsSuccess(products.products));
    } catch (e) {
      dispatch(receiveHomeFavoriteProductsError(e));
    }
  };
};

export const loadHomeFavoritesProductsTest = () => {
  return async (dispatch, getState, API) => {
    const lang = langSelector(getState());
    const hostname = hostnameSelector(getState());

    try {
      const { products } = await API.loadElasticCategoryProducts(
        DEFAULT_CATEGORY_ID[hostname][lang],
        {
          limit: 10,
          onlyAvailable: 1,
          sort: 'sales_agg',
          sortDir: 'desc',
          categoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          langId: lang,
          domainId: resolveDomainByHostname(hostname),
          onlySubcategories: 1,
        },
      );
      /*
      dispatch(requestHomeFavoriteProducts());
      const { products } = await API.loadElasticCategoryProducts(
        DEFAULT_CATEGORY_ID[hostname][lang],
        {
          limit: 10,
          onlyAvailable: 1,
          sort: 'sales_agg',
          sortDir: 'desc',
          categoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          langId: lang,
          domainId: resolveDomainByHostname(hostname),
          onlySubcategories: 1,
        },
      );
      dispatch(receiveHomeFavoriteProductsSuccess(products.products));
      */
    } catch (e) {
      dispatch(receiveHomeFavoriteProductsError(e));
    }
  };
};

const requestHomeProductList2 = () => ({
  type: REQUEST_HOME_PRODUCT_LIST2,
});

const receiveHomeProductList2Success = (products, categoryId: string) => ({
  type: RECEIVE_HOME_PRODUCT_LIST2_SUCCESS,
  payload: {
    products,
    categoryId,
  },
});

const receiveHomeProductList2Error = error => ({
  type: RECEIVE_HOME_PRODUCT_LIST2_ERROR,
  payload: {
    error,
  },
});

export const loadHomeProductList2 = (categoryId: string) => {
  return async (dispatch, getState, API) => {
    try {
      const state = getState();
      const news = productList2Selector(state);

      if (!news || !news.productsData || !news.productsData[categoryId]) {
        dispatch(requestHomeProductList2());
        const products = await API.loadProductList('ACTION_PACK');
        dispatch(receiveHomeProductList2Success(products, categoryId));
      }
    } catch (e) {
      dispatch(receiveHomeProductList2Error(e));
    }
  };
};

const requestFavoriteCategories = () => ({
  type: REQUEST_HOME_CATEGORIES,
});

const receiveFavoriteCategoriesSuccess = homeCategories => ({
  type: RECEIVE_HOME_CATEGORIES_SUCCESS,
  payload: {
    homeCategories,
  },
});

const receiveFavoriteCategoriesError = error => ({
  type: RECEIVE_HOME_CATEGORIES_ERROR,
  payload: {
    error,
  },
});

export const loadFavoriteCategories = () => {
  return async dispatch => {
    try {
      dispatch(requestFavoriteCategories());
      const homeCategories = await API.loadCategories({
        isFavorite: '1',
      });
      if (homeCategories && homeCategories[0]) {
        dispatch(receiveFavoriteCategoriesSuccess(homeCategories));
      }
    } catch (e) {
      dispatch(receiveFavoriteCategoriesError(e));
    }
  };
};

export const setHomeArticlesFilters = filters => ({
  type: SET_HOME_ARTICLES_FILTERS,
  payload: {
    filters,
  },
});

const requestHomeRecommended = () => ({
  type: REQUEST_HOME_RECOMMENDED,
});

const receiveHomeRecommendedSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_ERROR,
  payload: {
    error,
  },
});

const setHomeRecommendedFilter = filter => ({
  type: SET_HOME_RECOMMENDED_FILTER,
  payload: {
    filter,
  },
});

export const loadHomeRecommended = filter => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeRecommended());
      if (filter && filter !== getState().homepage.recommended.filter) {
        dispatch(setHomeRecommendedFilter(filter));
      }
      const lang = langSelector(getState());

      let tree =
        getState().recommended &&
        getState().recommended.recommendedArticlesOptions;
      if (!tree) {
        const subtree = await API.loadSitemapSubtree(
          'ODPORUCANE',
          {},
          {
            xAcceptLanguage: lang,
          },
        );
        tree = prop(subtree, 'sitemap_tree', []);
        dispatch(loadHomeRecommendedOptions(tree));
      }
      const firstOptionId = tree && tree[0] && tree[0].id;

      const id = parseInt(filter, 10) || firstOptionId || 0;
      if (id) {
        const articles = await API.loadArticles(
          {
            sitemapId: id,
            limit: 5,
            sfForm: 'Article',
            onlyActual: '1',
            isPublished: '1',
            tagExcludeId: 1518,
          },
          { xAcceptLanguage: lang },
        );
        dispatch(receiveHomeRecommendedSuccess(articles));
      } else {
        dispatch(receiveHomeRecommendedSuccess([]));
      }
    } catch (e) {
      dispatch(receiveHomeRecommendedError(e));
    }
  };
};

const receiveHomeRecommendedOptionsSuccess = recommended => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_SUCCESS,
  payload: {
    recommended,
  },
});

const receiveHomeRecommendedOptionsError = error => ({
  type: RECEIVE_HOME_RECOMMENDED_OPTIONS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeRecommendedOptions = options => {
  return async (dispatch, getState, API) => {
    try {
      await dispatch(receiveHomeRecommendedOptionsSuccess(options));
    } catch (e) {
      dispatch(receiveHomeRecommendedOptionsError(e));
    }
  };
};

const requestHomeFastNews = () => ({
  type: REQUEST_HOME_FAST_NEWS,
});

const receiveHomeFastNewsSuccess = fastNews => ({
  type: RECEIVE_HOME_FAST_NEWS_SUCCESS,
  payload: {
    fastNews,
  },
});

const receiveHomeFastNewsError = error => ({
  type: RECEIVE_HOME_FAST_NEWS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeFastNews = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeFastNews());
      const lang = langSelector(getState());
      const articles = await API.loadArticles(
        {
          sitemapUniqueId: 'BLESKOVKY',
          limit: 3,
          sfForm: 'Article',
          onlyActual: '1',
          isPublished: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeFastNewsSuccess(articles));
    } catch (e) {
      dispatch(receiveHomeFastNewsError(e));
    }
  };
};

const requestHomeTopArticle = () => ({
  type: REQUEST_HOME_TOP_ARTICLE,
});

const receiveHomeTopArticlesSuccess = topArticle => ({
  type: RECEIVE_HOME_TOP_ARTICLE_SUCCESS,
  payload: {
    topArticle,
  },
});

const receiveHomeTopArticleError = error => ({
  type: RECEIVE_HOME_TOP_ARTICLE_ERROR,
  payload: {
    error,
  },
});

export const loadHomeTopArticle = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeTopArticle());
      const lang = langSelector(getState());
      const topArticle = await API.loadArticles(
        {
          isTop: '1',
          sfForm: 'Article',
          onlyActual: '1',
          tagExcludeId: 1518,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeTopArticlesSuccess(topArticle));
    } catch (e) {
      dispatch(receiveHomeTopArticleError(e));
    }
  };
};

const receiveHomeBannerSuccess = banner => ({
  type: RECEIVE_HOME_BANNER_SUCCESS,
  payload: {
    banner,
  },
});

export const loadHomeBanner = () => {
  return async (dispatch, getState, API) => {
    try {
      const lang = langSelector(getState());
      const hostname = hostnameSelector(getState());
      const banners = await API.loadBanners(
        { position: resolveTagByHostname(hostname) },
        { xAcceptLanguage: lang },
      );
      const banner = prop(banners, 'banners').sort((a, b) =>
        a.b_sort > b.b_sort ? 1 : -1,
      );
      if (banner && banner.length > 0) {
        dispatch(receiveHomeBannerSuccess(banner));
      } else {
        dispatch(receiveHomeBannerSuccess(null));
      }
    } catch (e) {
      // console.log(e);
    }
  };
};

const receiveHomeTopBrandsSuccess = topBrand => ({
  type: RECEIVE_HOME_TOP_BRANDS_SUCCESS,
  payload: {
    topBrand,
  },
});

export const loadTopBrands = () => {
  return async (dispatch, getState, API) => {
    try {
      const brands = await API.loadBrands();
      const topBrand = prop(brands, 'brands');
      if (topBrand && topBrand.length > 0) {
        dispatch(receiveHomeTopBrandsSuccess(topBrand));
      } else {
        dispatch(receiveHomeTopBrandsSuccess(null));
      }
    } catch (e) {
      // console.log(e);
    }
  };
};

const requestHomeEbooks = () => ({
  type: REQUEST_HOME_EBOOKS,
});

const receiveHomeEbooksSuccess = ebooks => ({
  type: RECEIVE_HOME_EBOOKS_SUCCESS,
  payload: {
    ebooks,
  },
});

const receiveHomeEbooksError = error => ({
  type: RECEIVE_HOME_EBOOKS_ERROR,
  payload: {
    error,
  },
});

export const loadHomeEbooks = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestHomeEbooks());
      const lang = langSelector(getState());
      const ebooks = await API.loadArticles(
        {
          limit: 3,
          sitemapUniqueId: 'EBOOKY',
          onlyActual: '1',
          isPublished: '1',
          tagExcludeId: 1518,
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveHomeEbooksSuccess(ebooks));
    } catch (e) {
      dispatch(receiveHomeEbooksError(e));
    }
  };
};

const requestReviews = () => ({
  type: REQUEST_REVIEWS,
});

const receiveReviewsSuccess = reviews => ({
  type: RECEIVE_REVIEWS_SUCCESS,
  payload: {
    reviews,
  },
});

const receiveReviewsError = error => ({
  type: RECEIVE_REVIEWS_ERROR,
  payload: {
    error,
  },
});

export const loadReviews = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestReviews());
      const lang = langSelector(getState());
      const hostname = hostnameSelector(getState());
      const reviews = await API.loadArticles(
        {
          limit: 2,
          sitemapUniqueId: `RADCA_${resolveTagByHostname(hostname)}`,
          isPublished: '1',
          onlyActual: '1',
          // tagId: resolveTagIdByHostname(hostname),
          sfForm: 'Article',
          sort: 'created_date',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveReviewsSuccess(reviews));
    } catch (e) {
      dispatch(receiveReviewsError(e));
    }
  };
};

const requestNotFoundArticles = () => ({
  type: REQUEST_NOT_FOUND_ARTICLES,
});

const receiveNotFoundArticlesSuccess = notFoundArticles => ({
  type: RECEIVE_NOT_FOUND_ARTICLES_SUCCESS,
  payload: {
    notFoundArticles,
  },
});

const receiveNotFoundArticlesError = error => ({
  type: RECEIVE_NOT_FOUND_ARTICLES_ERROR,
  payload: {
    error,
  },
});

export const loadNotFoundArticles = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestNotFoundArticles());
      const lang = langSelector(getState());
      const hostname = hostnameSelector(getState());
      const notFoundArticles = await API.loadArticles(
        {
          sort: 'created_date',
          sortDir: 'desc',
          limit: 4,
          sitemapUniqueId: `BLOG_${resolveTagByHostname(hostname)}`,
          isPublished: '1',
          withoutStats: '1',
          onlyActual: '1',
          withoutJsonContent: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveNotFoundArticlesSuccess(notFoundArticles));
    } catch (e) {
      dispatch(receiveNotFoundArticlesError(e));
    }
  };
};

const requestBlogArticles = () => ({
  type: REQUEST_BLOG_ARTICLES,
});

const receiveBlogArticlesSuccess = articles => ({
  type: RECEIVE_BLOG_ARTICLES_SUCCESS,
  payload: {
    articles,
  },
});

const receiveBlogArticlesError = error => ({
  type: RECEIVE_BLOG_ARTICLES_ERROR,
  payload: {
    error,
  },
});

export const loadBlogArticles = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestBlogArticles());
      const lang = langSelector(getState());
      const hostname = hostnameSelector(getState());
      const reviews = await API.loadArticles(
        {
          sort: 'created_date',
          sortDir: 'desc',
          limit: 18,
          sitemapUniqueId: `BLOG_${resolveTagByHostname(hostname)}`,
          hasProductsFromCategoryId: DEFAULT_CATEGORY_ID[hostname][lang],
          sitemapOrProductCategory: true,
          isPublished: '1',
          withoutStats: '1',
          onlyActual: '1',
          withoutJsonContent: '1',
        },
        { xAcceptLanguage: lang },
      );
      dispatch(receiveBlogArticlesSuccess(reviews));
    } catch (e) {
      dispatch(receiveBlogArticlesError(e));
    }
  };
};
