import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  FlexBetween,
  FlexCol,
  DsiMobileNavigationItem as MobileNavItem,
  DsiItemType as ItemType,
  TextP,
  DsiMobileNavSubItem as MobileNavSubItem,
  DsiSvgIcon as SvgIcon,
  DsiIconType,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { getImagePath } from '../../utilities/product';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { __, __r } from 'react-i18n';
import { prop } from '../../utilities';
import { resolveCategoryUrl } from 'eshop-defaults/lib/utilities/selectors';

export interface Props {
  items: ThenArg<typeof API.loadTree>;
  closeMenu: () => void;
  showSearch: () => void;
  lang: string;
  navArticles: any;
  byId: any;
}

function MobileNavigation({
  items,
  closeMenu,
  showSearch,
  lang,
  navArticles,
  byId,
}: Props) {
  const [level, setLevel] = useState<number>(1);
  const [title, setTitle] = useState<string>('Menu');
  const [currentItems, setCurrentItems] = useState<any>(items);
  const [previous, setPrevious] = useState<
    Array<{ items: any; title: string }>
  >([]);

  const upLevel = (id: number = -1) => {
    const currentLvl = level;

    if (currentLvl === 1) {
      setPrevious([
        {
          items,
          title: 'Menu',
        },
      ]);
      setLevel(level + 1);
      setTitle('Produkty');
    }

    if (currentLvl > 1 && id >= 0) {
      const item = currentItems[id];
      if (item) {
        const newItems = currentItems[id].children;
        const newTitle = currentItems[id].category_name;
        const newPrevious = previous;
        newPrevious.push({
          items: currentItems,
          title,
        });
        setPrevious(newPrevious);
        setLevel(level + 1);
        setTitle(newTitle);
        setCurrentItems(newItems);
      }
    }
  };

  const downLevel = () => {
    const currentLvl = level;

    if (currentLvl > 1) {
      setLevel(level - 1);
      if (previous && previous.length) {
        setTitle(previous[previous.length - 1].title);
        setCurrentItems(previous[previous.length - 1].items);

        const newPrevious = [...previous];
        newPrevious.pop();
        setPrevious(newPrevious);
      }
    }
  };

  return (
    <Wrapper>
      <IconTitleWrapper onClick={downLevel}>
        {level > 1 && (
          <IconWrapper onClick={downLevel}>
            <SvgIcon
              icon={IconType.back}
              alt={__('Zavrieť menu')}
              viewBox={'0 0 12 12'}
              width={12}
              height={12}
              cursor={'pointer'}
              // tslint:disable-next-line:jsx-no-lambda
              fill="#000000"
            />
          </IconWrapper>
        )}
        <Title isFirstLevel={level === 1}>{title}</Title>
        {level > -1 && (
          <HigherLevelIconWrapper onClick={closeMenu}>
            <SvgIcon
              icon={IconType.x}
              alt={__('Zavrieť menu')}
              viewBox={'0 0 12 12'}
              width={12}
              height={12}
              cursor={'pointer'}
              // tslint:disable-next-line:jsx-no-lambda
              fill="#000000"
            />
          </HigherLevelIconWrapper>
        )}
      </IconTitleWrapper>
      {renderContentItems(
        level,
        upLevel,
        closeMenu,
        currentItems,
        showSearch,
        lang,
        navArticles,
        byId,
      )}
    </Wrapper>
  );
}

const renderContentItems = (
  level,
  upLevel,
  closeMenu,
  currentItems,
  showSearch,
  lang,
  navArticles,
  byId,
) => {
  const rightSrc = '/images/zemplin/list-arrow_black.svg';

  if (level === 1) {
    return (
      <MenuItemsWrapper>
        <MobileNavItem
          onClick={upLevel}
          type={ItemType.PRIMARY}
          title={
            <FlexBetween>
              <span>{__('Produkty')}</span>
              <SvgIcon
                icon={IconType.rightArrow}
                width={16}
                height={16}
                viewBox={'0 4 16 16'}
                alt={'Zobraziť podkategórie'}
                fill="#000000"
              />
            </FlexBetween>
          }
        />

        {navArticles.map((navArticle, i) => {
          if (
            prop(navArticle, 'uniqid', '') &&
            prop(navArticle, 'uniqid', '').includes('AKCIE')
          ) {
            return (
              <MobileNavItem
                onClick={closeMenu}
                url={`/${navArticle.url}`}
                type={ItemType.ACTION}
                title={navArticle.name}
              />
            );
          }

          return null;
        })}

        {navArticles.map(article => {
          if (
            prop(article, 'uniqid', '') &&
            prop(article, 'uniqid', '').includes('AKCIE')
          ) {
            return null;
          }
          return (
            <MobileNavItem
              onClick={closeMenu}
              linkUrl={article.link}
              url={`/${article.url}`}
              type={ItemType.TERTIARY}
              title={article.name}
            />
          );
        })}
        <MobileNavItem
          onClick={closeMenu}
          url={__r('routes:predajcovia', '/predajcovia')}
          type={ItemType.TERTIARY}
          title={__('Predajcovia')}
        />
      </MenuItemsWrapper>
    );
  }

  if (level > 1) {
    return (
      currentItems &&
      currentItems.map((category, i) => {
        return (
          <MobileNavSubItem
            key={category.category_id}
            imgSrc={
              category.image
                ? getImagePath(
                    category.image,
                    {
                      width: 24,
                      height: 24,
                    },
                    false,
                    false,
                    true,
                  )
                : '/images/zemplin/placeholder.svg'
            }
            showArrow={category.children.length > 0}
            arrowSrc={rightSrc}
            name={
              prop(category, 'publish.0.content.json_content.shortname') ||
              prop(category, 'publish.0.category_name') ||
              category.category_name
            }
            url={prop(category, 'publish.0.url') || category.url}
            weburl={
              prop(category, 'publish.0.redirect_url')
                ? prop(category, 'publish.0.redirect_url')
                : category.weburl && category.redirect_category_id
                ? resolveCategoryUrl(
                    category.redirect_category_id,
                    prop(
                      byId,
                      `${category.redirect_category_id}.publish.0.url`,
                    ) || category.weburl,
                    category.category_number &&
                      category.category_number.includes('BAZAR')
                      ? 'URL'
                      : 'CATEGORYTEXT/URL',
                  )
                : null
            }
            id={category.category_id}
            onClickLink={closeMenu}
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => (category.children.length ? upLevel(i) : null)}
          />
        );
      })
    );
  }

  return null;
};

const Wrapper = styled.div`
  width: 100%;
`;

const IconTitleWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  /* justify-content: flex-start; */
  margin-bottom: ${rem(6)};
  padding: 0 ${rem(16)};

  padding-left: 0;
  padding-right: 0;
`;

const IconWrapper = styled.div`
  padding: ${rem(16)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-block;

  svg {
    vertical-align: baseline;
  }
`;

const HigherLevelIconWrapper = styled.div`
  padding: ${rem(16)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-block;

  svg {
    vertical-align: baseline;
  }
`;

const MenuItemsWrapper = styled(FlexCol)``;

const Title = styled(TextP)<{ isFirstLevel }>`
  font-weight: 500;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-left: ${({ isFirstLevel }) => (isFirstLevel ? rem(16) : rem(-4))};
  display: inline-block;
  flex: 1 1 auto;
`;

export { MobileNavigation };
