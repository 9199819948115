import { __r } from 'react-i18n';
import {
  langSelector,
  currencySelector,
  hostnameSelector,
} from '../App/selectors';
import { saveCartCookie, removeCartCookie } from './helpers';
import { fetchCart, saveCart, updateCart } from './cartSlice';
import { resolveDomainByHostname } from '../../configureTrans';

export enum cartRoutes {
  INDEX = 'routes:kosik',
}

export const cartIndexRoute = __r('routes:kosik', '/kosik');

export const CART_ROUTES = {
  1: cartIndexRoute,
};

export const createNewCart = async (state, API, dispatch?: any) => {
  const { id } = await API.createCart(
    {},
    {
      xAcceptLanguage: langSelector(state),
      xCurrency: currencySelector(state),
    },
  );

  removeCartCookie();
  saveCartCookie(id);
  if (dispatch) {
    const cart = await API.getCart(id, { vatGroups: 1 });
    await dispatch(saveCart(cart));
  }

  return id;
};
