import { AppThunk, RootState } from './../../rootReducer';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import APIClass, { ThenArg } from '../../services/API';
import {
  ErrorTypeAPI,
  startFetching,
  stopFetching,
} from '../../utilities/redux';
import { saveCartCookie, loadCartCookie, removeCartCookie } from './helpers';
import { langSelector, userSelector } from '../App/selectors';
import { prop, arrayToObject, round } from '../../utilities';
import {
  getProductDefaultInfo,
  getElasticCategoryProductInfo,
} from 'eshop-defaults/lib/utilities/selectors';
import { createNewCart } from './utilities';
import {
  categoryDataSelector,
  categoryProductsSelector,
} from '../Category/selectors';
import * as cookie from 'react-cookies';
import { USER_COOKIE } from 'react-auth/lib/containers/Auth/constants';
import { resolveDomainByHostname } from '../../configureTrans';
import { hostnameSelector, currencySelector } from '../App/selectors';
import { heurekaPurchase } from '@bart.sk-ecommerce/react-online-marketing';
import { mapCategoriesTreeByIdRecursive } from '../Header/reducer';
import { __ } from 'react-i18n';

interface CartState {
  data: ThenArg<typeof APIClass.getCart> | null;
  error: ErrorTypeAPI;
  isFetching: boolean;
  showProblems: boolean;
  zoneId: number | null;
  deliveryPaymentInfo: {
    data: ThenArg<typeof APIClass.getDeliveryPaymentInfo> | null;
    byId: Record<
      string,
      ThenArg<typeof APIClass.getDeliveryPaymentInfo>
    > | null;
    ids: string[];
    isFetching: boolean;
    error: ErrorTypeAPI;
  };
  addToCartModal: boolean;
  addToCartModalProduct: any;
  addToCartModalIsRequest: boolean;
  addToCartModalIsFromCategory: boolean;
  wasAddedToCartModal: boolean;
  wasAddedToCartModalProduct: any;
  wasAddedToCartModalCount: number;
  wasAddedToCartModalFetching: boolean;
  wasAddedToCartModalBoughtToo: ThenArg<typeof APIClass.searchProducts> | null;
  couponError: string | null;
}

const initialState: CartState = {
  data: null,
  isFetching: false,
  error: null,
  showProblems: false,
  zoneId: null,
  deliveryPaymentInfo: {
    data: null,
    isFetching: false,
    error: null,
    byId: null,
    ids: [],
  },
  addToCartModal: false,
  addToCartModalProduct: null,
  addToCartModalIsRequest: false,
  addToCartModalIsFromCategory: false,
  wasAddedToCartModal: false,
  wasAddedToCartModalProduct: null,
  wasAddedToCartModalCount: 0,
  wasAddedToCartModalFetching: false,
  wasAddedToCartModalBoughtToo: null,
  couponError: null,
};

const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setShowProblems(state, action: PayloadAction<{ show: boolean }>) {
      state.showProblems = action.payload.show;
    },
    setCartZoneId(state, action: PayloadAction<{ zoneId: number | null }>) {
      state.zoneId = action.payload.zoneId;
    },
    setInitialCart(state) {
      state.data = null;
      state.error = null;
      stopFetching(state, '');
    },
    setCartProblems(state: any, action: PayloadAction<{ problems: any[] }>) {
      state.data = { ...state.data, problems: action.payload.problems };
    },
    startFetchingCart(state) {
      startFetching(state, '');
    },
    fetchCartSuccess(
      state,
      action: PayloadAction<{
        cart: ThenArg<typeof APIClass.getCart>;
      }>,
    ) {
      state.data = action.payload.cart;
      state.error = null;
      stopFetching(state, '');
    },
    fetchCartError(state, action: PayloadAction<ErrorTypeAPI>) {
      // state.data = null;
      state.error = action.payload;
      stopFetching(state, '');
    },

    startFetchingDelPayInfo(state) {
      startFetching(state, 'deliveryPaymentInfo');
    },
    fetchDelPayInfoSuccess(
      state,
      action: PayloadAction<{
        ids: string[];
        deliveriesById: Record<
          string,
          ThenArg<typeof APIClass.getDeliveryPaymentInfo>
        >;
      }>,
    ) {
      state.deliveryPaymentInfo.ids = action.payload.ids;
      state.deliveryPaymentInfo.byId = action.payload.deliveriesById;
      stopFetching(state, 'deliveryPaymentInfo');
    },
    fetchDelPayInfoError(state, action: PayloadAction<ErrorTypeAPI>) {
      // state.data = null;
      state.error = action.payload;
      stopFetching(state, 'deliveryPaymentInfo');
    },

    setAddToCartModal(
      state,
      action: PayloadAction<{
        product?: any;
        isRequest?: boolean;
        isFromCategory?: boolean;
      }>,
    ) {
      state.addToCartModal = action.payload.product
        ? !state.addToCartModal
        : false;
      state.addToCartModalProduct = action.payload.product || null;
      state.addToCartModalIsRequest = action.payload.isRequest || false;
      state.addToCartModalIsFromCategory =
        action.payload.isFromCategory || false;
    },

    hideAddToCartModal(state) {
      state.addToCartModal = false;
      state.addToCartModalProduct = null;
    },

    setWasAddedToCartModal(
      state,
      action: PayloadAction<{ product?: any; count?: number }>,
    ) {
      state.wasAddedToCartModal = !state.wasAddedToCartModal;
      state.wasAddedToCartModalProduct = action.payload.product || null;
      state.wasAddedToCartModalCount = action.payload.count || 0;
    },

    fetchProductInCartRecommended(state) {
      state.wasAddedToCartModalFetching = true;
    },
    setProductInCartRecommendedSuccess(
      state,
      action: PayloadAction<{
        connections: ThenArg<typeof APIClass.searchProducts>;
      }>,
    ) {
      state.wasAddedToCartModalBoughtToo = action.payload.connections;
      state['wasAddedToCartModalFetching'] = false;
    },
    setProductInCartRecommendedError(
      state,
      action: PayloadAction<ErrorTypeAPI>,
    ) {
      state.error = action.payload;
      state.wasAddedToCartModalFetching = false;
    },
    setCouponError(state, action: PayloadAction<string>) {
      state.couponError = action.payload;
    },
  },
});

const {
  startFetchingCart,
  fetchCartSuccess,
  fetchCartError,
  startFetchingDelPayInfo,
  fetchDelPayInfoSuccess,
  fetchDelPayInfoError,
  setAddToCartModal,
  hideAddToCartModal,
  setWasAddedToCartModal,
  fetchProductInCartRecommended,
  setProductInCartRecommendedSuccess,
  setProductInCartRecommendedError,
  setInitialCart,
  setShowProblems,
  setCartZoneId,
  setCartProblems,
  setCouponError,
} = cart.actions;
export default cart.reducer;

const cartDomainSelector = (state: RootState) => state.cart;

export const cartDataSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'data', null),
);

export const couponErrorSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'couponError', null),
);

export const cartPriceSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'data.total_items_price', 0),
);

export const cartCurrencySelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'data.currency', ''),
);

export const cartIdSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'data.id', null),
);

export const cartIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'isFetching', null),
);

export const cartErrorSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'error', null),
);

export const cartShowProblemsSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'showProblems', false),
);

export const cartZoneIdSelector = createSelector(cartDomainSelector, substate =>
  prop(substate, 'zoneId', false),
);

export const cartDelPayInfoIdsSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.ids', null),
);

export const cartDelPayInfoByIdSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.byId', null),
);

export const cartDelPayInfoIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'deliveryPaymentInfo.isFetching', null),
);

export const cartFreeDelPayInfoSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'freeDelivery.info', null),
);

export const addToCartModalVisibleSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModal', false),
);

export const addToCartModalProductSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModalProduct', null),
);

export const addToCartModalIsRequestSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModalIsRequest', false),
);

export const addToCartModalIsFromCategorySelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'addToCartModalIsFromCategory', false),
);

export const wasAddedToCartModalProductSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalProduct', null),
);

export const wasAddedToCartModalSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModal', false),
);

export const wasAddedToCartModalCountSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalCount', 0),
);

export const wasAddedToCartModalIsFetchingSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalFetching', false),
);

export const wasAddedToCartModalBoughtTooSelector = createSelector(
  cartDomainSelector,
  substate => prop(substate, 'wasAddedToCartModalBoughtToo', null),
);

export const setAddToCartModalVisibility = (
  isRequest?: boolean,
  product?: any,
  isFromCategory?: boolean,
): AppThunk => dispatch => {
  dispatch(setAddToCartModal({ product, isRequest, isFromCategory }));
};

export const hideAddToCartModalVisibility = (): AppThunk => dispatch => {
  dispatch(hideAddToCartModal());
};

export const setWasAddedToCartModalVisibility = (
  product?: any,
  count?: number,
): AppThunk => dispatch => {
  dispatch(setWasAddedToCartModal({ product, count }));
};

export const setCartError = (problems: any[]): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  dispatch(setCartProblems({ problems }));
};

export const replaceCurrentCart = (cartId: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  if (cartId) {
    removeCartCookie();
    saveCartCookie(cartId);
    try {
      dispatch(startFetchingCart());
      const cart = await API.getCart(cartId, { vatGroups: 1 });
      dispatch(fetchCartSuccess({ cart }));
    } catch (err) {
      dispatch(fetchCartError(err));
    }
  }
};

export const fetchCart = (
  refresh: boolean = false,
  cartCookieSsr?: string,
  useSSRCookie = false,
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  let userCartId = prop(state, 'auth.user.cartId');
  const userCookie = cookie.load(USER_COOKIE);
  const cartCookieCurrent = useSSRCookie ? cartCookieSsr : userCookie;

  if (refresh || !cartDataSelector(state)) {
    let cartId: string | undefined = userCartId || cartCookieCurrent;

    if (!cartId) {
      return;
      // cartId = await createNewCart(state, API);
    }
    if (cartId) {
      saveCartCookie(cartId);
      try {
        dispatch(startFetchingCart());
        const cart = await API.getCart(cartId, { vatGroups: 1 });
        dispatch(fetchCartSuccess({ cart }));
      } catch (err) {
        /*
        if (err.details.status === 404) {
          const newCartId = await createNewCart(
            state,
            API,
            null,
            `1->${cartId}->${cartCookieSsr}->${userCookie}`,
          );
          if (userCookie) {
            await dispatch(loginUser(userCookie, false));
          }
          await dispatch(fetchCartSimple(newCartId));
        } else {
          dispatch(fetchCartError(err));
        }
        */
        dispatch(fetchCartError(err));
      }
    }
  }
};

export const addItemToCartFromModal = (count: number = 1): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const product = addToCartModalProductSelector(state);
  // const isRequest = addToCartModalIsRequestSelector(state);
  let { productId, goodId } = getProductDefaultInfo(product);
  if (!productId || !goodId) {
    const elasticProductInfo = getElasticCategoryProductInfo(product);
    productId = elasticProductInfo.productId;
    goodId = elasticProductInfo.goodId;
  }

  let cartIdCookie = loadCartCookie();
  let cartId = prop(cartDataSelector(state), 'id');

  if (cartId && !cartIdCookie) {
    saveCartCookie(cartId);
  }
  if (!cartId) {
    cartId = await createNewCart(state, API, dispatch);
  }

  try {
    dispatch(startFetchingCart());
    const cart = await API.addCartItem(
      cartId,
      goodId,
      productId,
      {
        count,
        // isDemand: isRequest ? 1 : 0,
        currencyId: 'EUR',
        // vatGroups: 1,
      },
      {},
      { xAcceptLanguage: langSelector(state) },
    );
    dispatch(fetchCartSuccess({ cart }));
    dispatch(hideAddToCartModalVisibility());
    const user = userSelector(state);
    if (!user || !user.b2b) {
      dispatch(setWasAddedToCartModalVisibility(product, count));
    }
  } catch (err) {
    dispatch(fetchCartError(err));
    if (window) {
      window.alert(
        prop(err, 'description', 'Nastala chyba pri pridávaní do košíka'),
      );
    }
  }
};

export const addItemToCart = (
  product: any,
  count: number = 1,
  isDemand: boolean = false,
  openModalAfter: boolean = true,
  couponCode: string = '',
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  let { productId, goodId } = getProductDefaultInfo(product);
  if (!goodId) {
    goodId = product.main_good_id;
  }

  let cartIdCookie = loadCartCookie();
  let cartId = prop(cartDataSelector(state), 'id');

  if (cartId && !cartIdCookie) {
    saveCartCookie(cartId);
  }
  if (!cartId) {
    cartId = await createNewCart(state, API, dispatch);
  }

  const currency = currencySelector(state);

  try {
    dispatch(startFetchingCart());
    let cart = await API.addCartItem(
      cartId,
      goodId,
      productId,
      {
        count,
        // isDemand: isDemand ? 1 : 0,
        currencyId: currency ? currency : 'EUR',
        // vatGroups: 1,
      },
      {},
      { xAcceptLanguage: langSelector(state) },
    );

    const currentSales =
      product?.current_sales || product?.product_detail?.current_sales;

    if (currentSales?.length) {
      for (const currentSale of currentSales) {
        if (
          currentSale.type === 'GIFTS_FOR_PRICES' &&
          currentSale?.products_sale?.length >= 1
        ) {
          for (const productSale of currentSale.products_sale) {
            let {
              productId: saleProductId,
              goodId: saleGoodId,
            } = !productSale.month_price_avg
              ? getProductDefaultInfo(productSale)
              : {
                  productId: productSale.product_id,
                  goodId: productSale.good_id,
                };
            if (!saleGoodId) {
              saleGoodId = prop(productSale, 'main_good_id');
            }
            cart = await API.addCartItem(
              cartId,
              saleGoodId,
              saleProductId,
              {
                count: count * currentSale.sale_count,
                currencyId: currency,
              },
              {},
              { xAcceptLanguage: langSelector(state) },
            );
          }
        }
      }
    }

    dispatch(fetchDeliveryPaymentInfo());
    dispatch(fetchCartSuccess({ cart }));

    try {
      if (couponCode && !prop(cart, 'giftcards.0')) {
        await dispatch(addGiftCard(couponCode));
      }
    } catch (e) {}

    if (openModalAfter) {
      dispatch(setAddToCartModalVisibility(isDemand, null));
      const user = userSelector(state);
      if (!user || !user.b2b) {
        dispatch(setWasAddedToCartModalVisibility(product, count));
      }
    }
  } catch (err) {
    dispatch(fetchCartError(err));

    if (window) {
      window.alert(
        prop(
          err,
          'details.description',
          'Nastala chyba pri pridávaní do košíka',
        ),
      );
    }
  }
};

export const removeItemFromCart = (
  goodId: number,
  productId: number,
  couponCode: string = '',
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    const cart = await API.deleteCartItem(id, productId, goodId, {
      vatGroups: 1,
    });
    dispatch(fetchDeliveryPaymentInfo());
    dispatch(fetchCartSuccess({ cart }));

    try {
      if (couponCode && !prop(cart, 'giftcards.0')) {
        await dispatch(addGiftCard(couponCode));
      }
    } catch (e) {}
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const changeItemNote = (
  goodId: number,
  note: string,
): AppThunk => async (dispatch, getState, API) => {
  // const state = getState();
  // const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    // const cart = await API.addItemNote(
    //   id,
    //   goodId,
    //   { vatGroups: 1 },
    //   { note },
    //   { xAcceptLanguage: langSelector(state) },
    // );
    // dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const changeCartNote = (note: string): AppThunk => async (
  dispatch,
  getState,

  API,
) => {
  // const state = getState();
  // const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());
    // const cart = await API.addNote(
    //   id,
    //   { vatGroups: 1 },
    //   { note },
    //   { xAcceptLanguage: langSelector(state) },
    // );
    // dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const updateCart = (
  data: any,
  vatGroups: boolean = true,
  couponCode?: string,
): AppThunk => async (dispatch, getState, API) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    const hostname = hostnameSelector(state);
    dispatch(startFetchingCart());
    if (id && data.id) {
      const cart = await API.updateCart(
        id,
        { vatGroups: vatGroups ? 1 : 0 },
        { ...data, domain_id: resolveDomainByHostname(hostname) },
      );
      dispatch(fetchCartSuccess({ cart }));

      try {
        if (couponCode && !prop(cart, 'giftcards.0')) {
          await dispatch(addGiftCard(couponCode));
        }
      } catch (e) {}
    } else {
      dispatch(
        fetchCartError({
          name: 'Chyba',
          description: __('Košík nebol nájdený'),
          status: 404,
        }),
      );
    }
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const addGiftCard = (cardNumber: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());

    let cart = await API.addCard(
      id,
      { vatGroups: 1 },
      {
        card_type: 'GIFTCARD',
        card_number: cardNumber,
      },
    );

    dispatch(fetchCartSuccess({ cart }));
    dispatch(setCouponError(''));
  } catch (err) {
    if (err.details && err.details.description) {
      dispatch(setCouponError(err.details.description));
    } else {
      dispatch(setCouponError('Nepodarilo sa aplikovať poukážku.'));
    }
    dispatch(fetchCartError(err));
  }
};

export const removeGiftCard = (cardNumber: string): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingCart());

    const cart = await API.removeCard(id, 'giftcard', cardNumber, {
      vatGroups: 1,
    });

    dispatch(fetchCartSuccess({ cart }));
  } catch (err) {
    dispatch(fetchCartError(err));
  }
};

export const fetchDeliveryPaymentInfo = (): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();
  const { id } = cartDataSelector(state);

  try {
    dispatch(startFetchingDelPayInfo());
    const info = await API.getDeliveryPaymentInfo(
      id,
      {},
      { xAcceptLanguage: langSelector(state) },
    );
    const deliveriesById = arrayToObject(
      prop(info, 'delivery', []),
      'delivery_id',
    );

    const ids = prop(info, 'delivery', []).map(d => d.delivery_id);
    dispatch(fetchDelPayInfoSuccess({ ids, deliveriesById }));
  } catch (err) {
    dispatch(fetchDelPayInfoError(err));
  }
};

export const loadProductInCartRecommended = (product: any): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  try {
    dispatch(fetchProductInCartRecommended());

    let categoryId: any = null;
    const productCategory = prop(product, 'productCategories', [])
      ? prop(product, 'productCategories', [])[0]
      : null;
    if (productCategory) {
      categoryId = prop(productCategory, 'category_id');
    } else if (prop(categoryDataSelector(getState()), 'category_id')) {
      categoryId = prop(categoryDataSelector(getState()), 'category_id');
    } else {
      categoryId = null;
    }

    let catTotalProducts = 5;
    if (!categoryId) {
      catTotalProducts = 15000;
    } else {
      const categoryProducts = categoryProductsSelector(getState());
      const categoriesById = mapCategoriesTreeByIdRecursive(categoryProducts);
      catTotalProducts = prop(categoriesById[categoryId], 'counts', 5);
    }

    const lang = langSelector(getState());
    const hostname = hostnameSelector(getState());
    const connections = await API.searchProducts(
      {
        limit: 4,
        categoryId: categoryId ? categoryId : '',
        offset: Math.floor(Math.random() * Math.floor(catTotalProducts)),
        domainId: resolveDomainByHostname(hostname),
      },
      { xAcceptLanguage: lang },
    );
    dispatch(setProductInCartRecommendedSuccess({ connections }));
  } catch (e) {
    dispatch(setProductInCartRecommendedError(e));
  }
};

export const resetCart = (createNew = true): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  const state = getState();

  dispatch(setInitialCart());
  if (createNew) {
    await createNewCart(state, API, dispatch);
  }
};

export const showProblemsInCart = (
  show: boolean,
): AppThunk => async dispatch => {
  dispatch(setShowProblems({ show }));
};

export const setCartZone = (
  zoneId: number | null,
): AppThunk => async dispatch => {
  dispatch(setCartZoneId({ zoneId }));
};

export const handleGAPurchase = (order: any) => {
  return async (dispatch, getState, API) => {
    if (order && order.analytics_sended === 0) {
      const items: any[] = [];
      let sumOfItemsPriceWithoutVat = 0;
      let sumOfItemsPriceWithVat = 0;

      for (const item of order.items) {
        if (!item.product_id) {
        } else {
          sumOfItemsPriceWithoutVat += item.totalprice_without_vat;
          sumOfItemsPriceWithVat += item.totalprice;

          items.push({
            item_id: String(item.product_id),
            item_name: item.product_name,
            quantity: parseInt(item.quantity, 10),
            price: round(item.unitprice, 2),
            currency: order.order_tpricecurrency,
          });
        }
      }

      // gaPurchase(order, getGoogleAdsId());
      // fbPurchase(order);
      heurekaPurchase(order);

      const shipping = round(
        +(
          order.payment.payment_price +
          (order.payment.payment_price * order.payment.vat_rate) / 100
        ) +
          +(
            order.payment.delivery_price +
            (order.payment.delivery_price * order.payment.vat_rate) / 100
          ),
        2,
      );

      const user_type = order.isB2B ? 'b2b' : 'b2c';

      if (window && (window as any).gtag) {
        (window as any).gtag('event', 'purchase', {
          transaction_id: order.order_nr || order.order_id,
          affiliation: 'Edsi eshop',
          value: round(order.order_tprice, 2),
          currency: order.order_tpricecurrency,
          tax: round(sumOfItemsPriceWithVat - sumOfItemsPriceWithoutVat, 2),
          shipping: round(shipping, 2),
          user_type: user_type,
          items,
        });
      }

      try {
        await API.updateOrderAnalytics(order.public_id);
      } catch (e) {
        console.error('Update order analytics failed!');
      }
    }
  };
};

export const saveCart = (cart: any): AppThunk => async (
  dispatch,
  getState,
  API,
) => {
  dispatch(fetchCartSuccess({ cart }));
};
